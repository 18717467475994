import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import * as classes from './default.module.scss';
import logo from 'src/assets/logotype-white.svg';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import AppLink from 'src/components/base/appLink';
import 'styles/main.scss';

const Layout = ({ children, location, pageTitle }) => {
  const data = useStaticQuery(
    graphql`
      query {
        datoCmsSite {
          faviconMetaTags {
            ...GatsbyDatoCmsFaviconMetaTags
          }
        }
        datoCmsStart {
          seoMetaTags {
            ...GatsbyDatoCmsSeoMetaTags
          }
        }
      }
    `,
  );

  return (
    <>
      <HelmetDatoCms seo={data.datoCmsStart.seoMetaTags}>
        <title>{pageTitle}</title>
      </HelmetDatoCms>

      <div className={classes.app}>
        <header>
          <nav>
            <AppLink to="https://www.boulder.care/">
              <img alt="Boulder Care" src={logo} width="100" />
            </AppLink>
          </nav>
          <h1>{pageTitle}</h1>
        </header>
        <main className={classes.appMain}>{children}</main>
      </div>
    </>
  );
};

export default Layout;
