// https://www.gatsbyjs.org/docs/gatsby-link/#reminder-use-link-only-for-internal-links
import React from 'react';
import { Link } from 'gatsby';

const AppLink = ({ children, to, className, trackingId, onClick }) => {
  if (to.match(/^http(s)?:\/\//)) {
    return (
      <a
        href={to}
        target="_blank"
        rel="noreferrer"
        id={trackingId}
        onClick={onClick}
        className={className}
      >
        {children}
      </a>
    );
  } else if (to.match(/^mailto:/) || to.match(/^#/) || to.match(/^tel:/) || to.match(/^sms:/)) {
    return (
      <a href={to} id={trackingId} onClick={onClick} className={className}>
        {children}
      </a>
    );
  } else {
    return (
      <Link to={to} id={trackingId} onClick={onClick} className={className}>
        {children}
      </Link>
    );
  }
};

export default AppLink;
